@import '../vars.module.scss';

.isDown {
  filter: hue-rotate(300deg) saturate(210%) brightness(0.7) contrast(170%);
  margin-top: 10px;
  width: 250px;
}

.isUp {
  filter: hue-rotate(85deg) saturate(80%) brightness(0.85);
  margin-top: 10px;
  width: 250px;
}

.chartSection {
  border: 2px solid gray;
  border-radius: 10px;
  display: inline-block;
  padding: 10px;
  width: 35%;
}

.chartSection2 {
  border: 2px solid gray;
  border-radius: 10px;
  display: inline-block;
  padding: 10px;
  width: 60%;
}

.coinCharts {
  display: flex;
  flex-grow: 4;
  padding-bottom: 25px;
  padding-top: 25px;
  justify-content: space-evenly;
}

.coinGroup {
  display: flex;
  flex-grow: 4;
  padding-bottom: 50px;
  padding-top: 125px;
  justify-content: space-evenly;
}

.coinLogo {
  height: 48px;
  margin: 5px;
  width: 48px;
}

.coinName {
  float: right;
  width: calc(100% - 64px);
}

.coinPrice {
  color: black;
  display: block;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.section {
  border: 2px solid gray;
  border-radius: 10px;
  display: inline-block;
  padding: 10px;
  width: 250px;
}

.sectionUp {
  background-color: greenyellow;
}

.sectionDown {
  background-color: lightcoral;
}